<template>
  <div>
    <img v-if="isLoading" src="../../assets/images/loading_img.gif" />
    <h3 v-if="isLoading">Please wait. Loading..</h3>
    <div v-else>
      <h3>{{errorMessage}}</h3>
      <div v-html="orderInHtml"></div>
      <h4 v-if="isRequestUnderProcess" id="request-under-process-msg">
        <span id="attention-header">{{ labels.order.attentionHeader[language] }}</span>
        {{labels.order.requestUnderProcessMsg[language]}} {{phoneNumber}}
      </h4>
      <div id="buttons-container" v-if="isRequestUnderProcess">
        <button
          class="action-button"
          id="accept-button"
          @click="executeOrder"
        >{{labels.order.acceptRequest[language]}}</button>
        <button
          class="action-button"
          id="reject-button"
          @click="rejectRequest"
        >{{labels.order.rejectRequest[language]}}</button>
      </div>
      <div id="buttons-container" v-else-if="order && order.status=='PENDING_ACCEPTANCE'">
        <button class="action-button" id="accept-button" @click="acceptRequest">Αποδοχή</button>
        <button class="action-button" id="reject-button" @click="rejectRequest">Απόρριψη</button>
      </div>
      <h3
        class="successClass"
        v-if="order && order.status=='ACCEPTED' && !isRequestUnderProcess"
      >Η παραγγελία έγινε αποδεκτή</h3>
      <h3 class="errorClass" v-if="order && order.status=='REJECTED'">Η παραγγελία απορρίφθηκε</h3>
      <h3 v-if="error" class="errorClass">{{message}}</h3>
    </div>
  </div>
</template>

<script>
import OrderApi from "../../api/order";
export default {
  name: "SeeOrderDetails.vue",
  components: {
  },
  data() {
    return {
      errorMessage: "",
      message: "",
      underProcessMessage: "",
      isLoading: false,
      languageOnlyForPinScreen: 'ENG',
      isRequestUnderProcess: false,
      phoneNumber: '',
      isLoading: false,
      orderId: null,
      restaurantId: null,
      token: null,
      order: null,
      orderInHtml: "",
      error: false
    };
  },
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },
  },
  methods: {
    setTitle() {
      try {
        document.title = "Fluter";
      } catch (error) {
        console.log("Could not set title");
      }
    },
    async changeStatusForOrder(status) {
      this.error = false;
      this.isRequestUnderProcess = false;
      if (!this.orderId || !this.restaurantId || !this.token || !status) {
        this.message = "Missing data to change status for order"
        return;
      }

      this.isLoading = true;
      let result = await OrderApi.changeStatus(this.orderId, this.restaurantId, this.token, status);
      setTimeout(() => {
        OrderApi.sendResponseToUser(this.orderId, this.restaurantId, this.token);
      }, 1000);
      if (result.orderStatus == 'ERROR') {
        this.message = result.message[this.language];
        if (result.errorCode == 'ORDER_NOT_PENDING_STATUS' || result.errorCode == 'ORDER_CANNOT_BE_REJECTED') {
          let extraErrorMessage = status == 'ACCEPTED' ? this.labels.order.cannotAcceptOrder[this.language] : this.labels.order.cannotRejectOrder[this.language];
          this.message = this.message + ". " + extraErrorMessage;
        }
        this.error = true;
        this.isLoading = false;
        return;
      }

      let order = result.order;
      this.order = order;
      if (order.status == 'ACCEPTED' && !order.hasBeenExecuted && order.restaurant.delayedResponse) {
        this.phoneNumber = order.phoneNumber;
        this.isRequestUnderProcess = true;
        this.message = this.labels.order.orderUnderProccess[this.language];
        this.isLoading = false;
        return;
      }

      this.message = result.orderStatus == 'ACCEPTED' ? this.labels.order.succesfullyAcceptedOrder[this.language] : this.labels.order.succesfullyRejectedOrder[this.language];
      this.isLoading = false;
    },

    async getHtmlOrder() {
      this.underProcessMessage = ""
      if (!this.orderId || !this.restaurantId || !this.token) {
        this.errorMessage = "Missing data to show order"
        return;
      }

      this.errorMessage = ""
      this.isLoading = true;
      let result = await OrderApi.getHtmlOrder(this.orderId, this.restaurantId, this.token);

      if (result.orderStatus == 'ERROR') {
        this.errorMessage = result.message[this.language];
        this.isLoading = false;
        return;
      }

      this.orderInHtml = result.data.html;
      this.order = result.data.order;
      if (this.order.status == 'ACCEPTED' && !this.order.hasBeenExecuted && this.order.restaurant.delayedResponse) {
        this.isRequestUnderProcess = true;
        this.underProcessMessage = this.labels.order.orderUnderProccess[this.language];
      }

      this.isLoading = false;
    },

    async rejectRequest() {
      await this.changeStatusForOrder('REJECTED');
    },

    async acceptRequest() {
      await this.changeStatusForOrder('ACCEPTED');
    },

    async executeOrder() {
      this.isRequestUnderProcess = false;
      if (!this.orderId || !this.restaurantId || !this.token) {
        this.message = "Missing data to change status for order"
        return;
      }
      this.isLoading = true;
      let result = await OrderApi.changeExecution(this.orderId, this.restaurantId, this.token, true);
      if (result.orderStatus == 'ERROR') {
        this.message = result.message[this.language];
        this.isLoading = false;
        return;
      }
      this.message = this.labels.order.succesfullyAcceptedOrder[this.language];
      this.isLoading = false;
    }
  },
  async mounted() {
    this.setTitle();
    this.orderId = this.$router.currentRoute.params.orderId;
    this.restaurantId = this.$router.currentRoute.params.restaurantId;
    this.token = this.$router.currentRoute.params.token;
    await this.getHtmlOrder();
  }
}
</script>

<style scoped>
#order-info-container {
  margin-left: 8px;
  margin-top: 8px;
  width: 95%;
  max-width: 500px;
}

#request-under-process-msg {
  color: #007bff;
  margin-bottom: 20px;
}

#buttons-container {
  display: flex;
  flex-direction: row;
  max-width: 450px;
  justify-content: space-between;
}

.action-button {
  color: white;
  padding: 6px 10px 6px 10px;
  border-radius: 10px;
}

#accept-button {
  background-color: #198b4a;
}

.errorClass {
  margin: 6px;
  color: red;
}

.successClass {
  margin: 6px;
  color: #198b4a;
}

#reject-button {
  background-color: #d9534f;
}

#attention-header {
  color: #ff721f;
  font-weight: 700;
  margin-right: 2px;
}
</style>